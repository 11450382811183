@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #000000;
  width: 100%;
  overflow-x: hidden;
}


h3, h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin: 20px 0 10px;
}

.link-hover-animation {
    position: relative;
    transition: all 0.3s ease-in-out;
    padding-bottom: 0.4rem;
  }
  
  .link-hover-animation::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: #37b500;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .link-hover-animation:hover::after {
    transform: scaleX(1);
  }

  .custom-3d-font {
    text-shadow: 2px 2px 4px rgb(55, 181, 0);
    transform: perspective(90px) rotateY(10deg);
  }

  .no-money-disclaimer {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #E9EAEC; /* #E9EAEC text color */
    padding: 5px;
    display: flex;
    align-items: center;
    z-index: 10000;
  }
  
  .nomoney-icon {
    width: 15px;
  }
  
  .message {
    font-size: 16px;
    margin-left: 7px;
  }